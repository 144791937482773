import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';

import { PUBLIC_SENTRY_REPLAYS_ENABLED, PUBLIC_ENVIRONMENT } from '$env/static/public';
import { initSentry } from '$lib/utils/sentry';

function setupSentryTracking(initFunction: typeof initSentry = initSentry): void {
	initFunction({
		// If you don't want to use Session Replay, just remove:
		// "integrations: [new Replay()]," by setting PUBLIC_SENTRY_REPLAYS_ENABLED to false
		integrations: PUBLIC_SENTRY_REPLAYS_ENABLED === 'true' ? [new Replay()] : undefined,
		environment: PUBLIC_ENVIRONMENT,
	});
}
setupSentryTracking(initSentry);

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
